// import React, { Component } from 'react';
// import { HashRouter, Switch, Route } from "react-router-dom";
// import { loadReCaptcha } from 'react-recaptcha-v3';
// import './index.scss';

// import DefaultFooter from './containers/default-footer';
// import DefaultHeader from './containers/default-header';

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// // Pages
// const Home = React.lazy(() => import('./views/home'));

// const ConsumerOmbudsman = React.lazy(() => import('./views/consumer-ombudsman'));
// const ContactUs = React.lazy(() => import('./views/contact-us'));
// const CustomerFees = React.lazy(() => import('./views/customer-fees'));
// const Forms = React.lazy(() => import('./views/forms'));
// const Insurance = React.lazy(() => import('./views/insurance'));
// const Licensing = React.lazy(() => import('./views/licensing'));
// const LoanStatusCheck = React.lazy(() => import('./views/loan-status-check'));
// const MilitaryAssistance = React.lazy(() => import('./views/military-assistance'));
// const MortgageAssistance = React.lazy(() => import('./views/mortgage-assistance'));
// const NeedLoanNumber = React.lazy(() => import('./views/need-loan-number'));
// const NewMember = React.lazy(() => import('./views/new-member'));
// const PaymentOptions = React.lazy(() => import('./views/payment-options'));
// const Privacy = React.lazy(() => import('./views/privacy'));
// const Terms = React.lazy(() => import('./views/terms'));
// const FrequentlyAskedQuestions = React.lazy(() => import('./views/frequently-asked-questions'));

// class App extends Component {

//   componentDidMount() {

//     // Provide the site v3 token
//     if (loadReCaptcha) {
//       loadReCaptcha('6Le3ReAUAAAAABk6S7cONGm5WLvVvZN1cddpWyfM');
//     }
//   }

//   render() {
//     return (
//       <div>
//         <div id="content">
//           <div className="container">
//             <DefaultHeader />
          
//             <div>
//               <div id="primary">
//                 <main id="main" role="main">

//                   <HashRouter>
//                     <React.Suspense fallback={loading()}>
//                       <Switch>
//                         <Route exact path="/consumer-ombudsman" component={props => <ConsumerOmbudsman/>} />
//                         <Route exact path="/contact-us" component={props => <ContactUs/>} />
//                         <Route exact path="/customer-fees" component={props => <CustomerFees/>} />
//                         <Route exact path="/forms" component={props => <Forms/>} />
//                         <Route exact path="/insurance" component={props => <Insurance/>} />
//                         <Route exact path="/licensing" component={props => <Licensing/>} />
//                         <Route exact path="/loan-status-check" component={props => <LoanStatusCheck/>} />
//                         <Route exact path="/military-assistance" component={props => <MilitaryAssistance/>} />
//                         <Route exact path="/mortgage-assistance" component={props => <MortgageAssistance/>} />
//                         <Route exact path="/need-loan-number" component={props => <NeedLoanNumber/>} />
//                         <Route exact path="/new-member" component={props => <NewMember/>} />
//                         <Route exact path="/payment-options" component={props => <PaymentOptions/>} />
//                         <Route exact path="/privacy" component={props => <Privacy/>} />
//                         <Route exact path="/terms" component={props => <Terms/>} />
//                         <Route exact path="/frequently-asked-questions" component={props => <FrequentlyAskedQuestions/>} />

//                         <Route path="/" component={props => <Home/>} />
//                       </Switch>
//                     </React.Suspense>
//                   </HashRouter>

//                 </main>
//               </div>
//             </div>
//           </div>
//         </div>

//         <DefaultFooter />
//       </div>
//      );
//     }
//   }
  
//   export default App;


import React, { Component } from 'react';
import { HashRouter, Switch, Route } from "react-router-dom";
import { loadReCaptcha } from 'react-recaptcha-v3';
import './index.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Pages
const DefaultLayout = React.lazy(() => import('./containers/default-layout'));
const Coronavirus = React.lazy(() => import('./views/coronavirus'));

class App extends Component {

  componentDidMount() {

    if (loadReCaptcha) {
      loadReCaptcha('6Le3ReAUAAAAABk6S7cONGm5WLvVvZN1cddpWyfM');
    }
  }

  render() {
    return (
      <div>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/coronavirus" component={props => <Coronavirus/>} />

              <Route path="/" component={props => <DefaultLayout/>} />
            </Switch>
          </React.Suspense>
        </HashRouter>

      </div>
     );
  }
}
  
export default App;
